import React from 'react'

import { Link } from 'gatsby'

import { useCookies } from 'react-cookie'
import { routes } from '../../data'

const CookieNotice = () => {
    const cookieName = 'cookie_notice_agreement'
    const activeCookieValue = 'agreed'
    const [cookies, setCookie] = useCookies([cookieName])
    let aYearFromNow = new Date();
    aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);

    if (cookies.cookie_notice_agreement === undefined) {
        return (
            <div className="cookie-notice">
                <div className="cookie-notice__text">
                    We use cookies to optimize your user experience. By
                    continuing to use our site you agree to using cookies in
                    accordance with our
                    <Link
                        to={routes.privacyPolicy}
                        className="cookie-notice__text__link border-bottom-animation border-bottom-animation--small"
                    >
                        Privacy Policy
                    </Link>
                </div>
                <button
                    className="cookie-notice__btn border-bottom-animation"
                    onClick={() => {
                        setCookie(cookieName, activeCookieValue, {
                            path: '/',
                            expires: aYearFromNow
                        })
                    }}
                >
                    Agree & Close
                </button>
            </div>
        )
    }

    return null
}

export default CookieNotice
