import React from 'react'
import { Link } from 'gatsby'

import Chevron from './../presentationals/svg/Chevron'

import { routes } from '../../data'

export default function Footer() {
  const {
    privacyPolicy,
    about,
    projects,
    posts,
    clients,
    contact,
    social: { linkedIn, gitHub, codePen, facebook, instagram },
  } = routes

  return (
    <footer className="footer section-spacing">
      <div className="footer__inner container">
        <div className="footer__section">
          <div className="footer__section__title font-1">SITE MAP</div>
          <ul className="footer-list">
            <li className="footer-list__item">
              <a
                href={about}
                className="footer-list__item__link reset-link font-6"
                target="_blank"
                rel="noopener noreferrer"
              >
                About
              </a>
            </li>
            <li className="footer-list__item">
              <Link
                to="#main-content"
                className="footer-list__item__link reset-link font-6"
              >
                Projects
              </Link>
            </li>
            <li className="footer-list__item">
              <Link
                to="#contact-form"
                className="footer-list__item__link reset-link font-6"
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
        <div className="footer__section">
          <div className="footer__section__title font-1">SOCIAL</div>
          <ul className="footer-list">
            <li className="footer-list__item">
              <a
                className="footer-list__item__link reset-link font-6"
                href={linkedIn}
                target="_blank"
                rel="noopener noreferrer"
              >
                Linkedin
              </a>
            </li>
            <li className="footer-list__item">
              <a
                className="footer-list__item__link reset-link font-6"
                href={gitHub}
                target="_blank"
                rel="noopener noreferrer"
              >
                GitHub
              </a>
            </li>
          </ul>
        </div>
        <div className="footer__section">
          <div className="footer__section__title font-1">LEGAL</div>
          <ul className="footer-list">
            <li className="footer-list__item">
              <Link
                to={privacyPolicy}
                className="footer-list__item__link reset-link font-6"
              >
                Privacy Policy
              </Link>
            </li>
          </ul>
        </div>
        <div className="footer__section--long">
          <div className="footer__section--long__date font-6">
            Copyright © {new Date().getFullYear()}
          </div>
          <a
            href="#page-top"
            className="footer__section--long__top-arrow elevation-3"
          >
            <Chevron />
          </a>
        </div>
      </div>
    </footer>
  )
}
