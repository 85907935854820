import React from 'react'
import PropTypes from 'prop-types'

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

import '../../style/styles'

import Footer from './Footer'
import CookieNotice from './CookieNotice'

const theme = createMuiTheme({
  palette: {
    primary: { main: '#304ffd' },
    secondary: { main: '#c92525' },
  },
})

export const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <main>{children}</main>
      <Footer />
      <CookieNotice />
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
