export const routes = {
  home: '/',
  privacyPolicy: '/privacy-policy',
  about: 'https://uk.linkedin.com/in/jeronimas-dargis-668693186',
  contact: '/contact',
  posts: '/posts',
  clients: '/clients',
  projects: '/projects',
  projectLinks: {
    rcco: 'https://rcco.uk/',
    wild: 'https://tothewild.co.uk/',
    nozzle: 'https://www.nozzle.ai/',
    adLib: 'https://ad-lib.io/',
    google: 'https://www.google.com/',
  },
  social: {
    linkedIn: 'https://uk.linkedin.com/in/jeronimas-dargis-668693186',
    gitHub: 'https://github.com/JeronimasDargis',
  },
}
